import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        name: 'notFound',
        path: '/:path(.*)+',
        redirect: {
            name: 'home',
        },
    },
    {
        name: 'login',
        path: '/login',
        component: () => import('./view/login'),
        meta: {
            title: '人才管理系统 用户登陆',
        },
    },
    {
        name: 'home',
        path: '/home',
        component: () => import('./view/home'),
        meta: {
            title: '人才管理系统',
        },
    }, 
    {
        name: 'ticketList',
        path: '/ticket/list',
        component: () => import('./view/ticket/list'),
        meta: {
            title: '工单管理',
        },
    }, 
    {
        name: 'companyList',
        path: '/company/list',
        component: () => import('./view/company/list'),
        meta: {
            title: '企业管理',
        },
    },
    {
        name: 'userList',
        path: '/user/list',
        component: () => import('./view/user/list'),
        meta: {
            title: '用户管理',
        },
    },
    {
        name: 'bind',
        path: '/bind',
        component: () => import('./view/bind'),
        meta: {
            title: '微信绑定',
        },
    },

    {
        name: 'logout',
        path: '/logout',
        component: () => import('./view/logout'),
        meta: {
            title: '退出登陆',
        },
    },


];

const router = createRouter({
    routes,
    history: createWebHashHistory(),
});

router.beforeEach( ( to, from, next ) => {

    const role = localStorage.getItem( 'accessToken' );
    if ( ! role && to.path !== '/login') {

        let redirectUrl = window.location.pathname + window.location.hash + window.location.search;     
        if ( redirectUrl == '/#/' || redirectUrl == '/#/logout' ) {

            next( '/login' );
        } else {

            next( '/login?redirectUrl=' + encodeURIComponent( redirectUrl  ) );
        }
        
    } else {

        next();
    }

});

export { router };
